/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useTheme } from '@mui/styles';
import aguila from '../../resources/fondo.png';

export default function HeaderTacker() {
  // const theme = useTheme();

  return (
    <AppBar position="abslute" elevation={1} sx={{ height: '40px' }}>
      <Toolbar sx={{ justifyContent: 'center', mt: -1 }}>
        <img src={aguila} width={190} height={25} />
      </Toolbar>
    </AppBar>
  );
}
