/* eslint-disable no-use-before-define */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  Alert, Paper, Snackbar, IconButton, Box, Modal, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, Link,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importa el icono de WhatsApp
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import DeviceList from './DeviceList';
import BottomMenu from '../common/components/BottomMenu';
import StatusCard from '../common/components/StatusCard';
import { useAdministrator } from '../common/util/permissions';
import { devicesActions } from '../store';
import usePersistedState from '../common/util/usePersistedState';
import EventsDrawer from './EventsDrawer';
import useFilter from './useFilter';
import MainToolbar from './MainToolbar';
import MainMap from './MainMap';
import { useAttributePreference } from '../common/util/preferences';
import HeaderTacker from '../common/components/HeaderTacker';
import aguila from '../resources/AguilaTop.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: 0,
      top: 40,
      height: `calc(100% - ${theme.spacing(5)})`,
      width: theme.dimensions.drawerWidthDesktop,
      margin: theme.spacing(0),
      zIndex: 13,
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
    },
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: 'grid',
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 4,
  },
  caducidad: {
    flex: 1,
    marginTop: 60,
  },
}));

// Estilos para cambiar el color al pasar el cursor por encima del enlace
const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const linkStyles = {
    textDecoration: 'none', // Elimina el subrayado
    color: isHovered ? 'green' : 'black',
    transition: 'color 0.3s', // Agrega una transición de color suave
    cursor: 'pointer', // Cambia el cursor a una mano señalando un enlace
  };

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const mapOnSelect = useAttributePreference('mapOnSelect', true);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);

  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);
  const administrador = useAdministrator();

  const [filteredDevices, setFilteredDevices] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
  });
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const [devicesOpen, setDevicesOpen] = useState(desktop);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [tipo, setTipo] = useState('');
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const handleClose = () => setOpen3(false);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);
  const [usuario, setUsuario] = useState(useSelector((state) => state.session.user));
  const caducidad = useSelector((state) => state.session.user.expirationTime);
  const clave = useSelector((state) => state.session.user.phone);
  const fechaActual = new Date();
  const cad1 = new Date(caducidad);
  const diferenciaEnMilisegundos = cad1.getTime() - fechaActual.getTime();
  const diffInDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: desktop ? '50%' : '90%',
  };

  const handleAcceptPolicyChange = (event) => {
    setAcceptPolicy(event.target.checked);
    if (event.target.checked) {
      const nuevoUsuario = {
        ...usuario,
        attributes: {
          ...usuario.attributes,
          hasPoliticas: true,
        },
      };
      setUsuario(nuevoUsuario);
    }
  };

  const handleGuardarClick = async () => {
    actualizarUsuario();
  };

  const actualizarUsuario = async () => {
    try {
      const response = await fetch(`/api/users/${usuario.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usuario),
      });
      if (response.status === 200) {
        setOpen2(false);
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const abrirRegistro = () => {
    setOpen1(false);
    setOpen2(true);
  };

  useEffect(() => {
    if (!administrador && !usuario.attributes.hasPoliticas) {
      setOpen3(true);
    }
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
    if (diffInDias <= 30) {
      setOpen(true);
    }
    if (!administrador && (clave === null || clave === '')) {
      setOpen1(true);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  useFilter(keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions);

  return (
    <div className={classes.root}>
      {desktop && <HeaderTacker />}
      <div>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{ position: 'absolute', marginTop: 3 }}
        >
          <Alert
            severity="error"
            sx={{ width: '80%', alignItems: 'center' }}
          >
            {diffInDias < 0 ? 'Su servicio ha caducado' : `Su servicio terminará en los próximos ${diffInDias} días`}
            <a href={`https://wa.me/+593987305823/?text=${encodeURI('Hola, necesito renovar mi servicio')}`} target="_blank" rel="noopener noreferrer" style={linkStyles} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Clic aquí para renovar
                <WhatsAppIcon sx={{ color: 'green', marginLeft: '5px' }} />
              </Box>
            </a>
          </Alert>
        </Snackbar>
      </div>
      {desktop && (
        <MainMap
          filteredPositions={filteredPositions}
          selectedPosition={selectedPosition}
          onEventsClick={onEventsClick}
        />
      )}
      <div className={classes.sidebar}>
        <Paper square elevation={1} className={classes.header}>
          <MainToolbar
            filteredDevices={filteredDevices}
            devicesOpen={devicesOpen}
            setDevicesOpen={setDevicesOpen}
            keyword={keyword}
            setKeyword={setKeyword}
            filter={filter}
            setFilter={setFilter}
            filterSort={filterSort}
            setFilterSort={setFilterSort}
            filterMap={filterMap}
            setFilterMap={setFilterMap}
          />
        </Paper>
        <div className={classes.middle}>
          {!desktop && (
            <div className={classes.contentMap}>
              <div style={{ position: 'relative' }}>
                <IconButton
                  style={{
                    position: 'absolute', // Centra el botón en relación al centro del contenedor
                    zIndex: 1,
                    color: 'red',
                    marginTop: '2%',
                    left: '2%',
                    backgroundColor: 'white',
                  }}
                  component="a"
                  target="_blank"
                  href={`https://wa.me/+593983442230/?text=${encodeURI('Hola, necesito ayuda')}`}
                  aria-label="Llamar"
                >
                  <CallIcon fontSize="large" />
                </IconButton>
              </div>
              <MainMap
                filteredPositions={filteredPositions}
                selectedPosition={selectedPosition}
                onEventsClick={onEventsClick}
              />
            </div>
          )}
          <Paper square className={classes.contentList} style={devicesOpen ? {} : { visibility: 'hidden' }}>
            <DeviceList devices={filteredDevices} />
          </Paper>
        </div>
        {desktop && (
          <div className={classes.footer}>
            <BottomMenu />
          </div>
        )}
      </div>
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      {selectedDeviceId && (
        <StatusCard
          deviceId={selectedDeviceId}
          position={selectedPosition}
          onClose={() => dispatch(devicesActions.select(null))}
          desktopPadding={theme.dimensions.drawerWidthDesktop}
        />
      )}
      <Modal open={open3}>
        <div style={{ ...modalStyle, border: '4px solid black', borderRadius: '8px', padding: '16px', margin: 'auto' }}>
          <Paper sx={{ bgcolor: 'background.paper', p: 2, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ marginBottom: 2, fontSize: desktop ? '16px' : '13px' }}>
              Aviso de Política de Protección de Datos Personales
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: 2, fontSize: desktop ? '16px' : '12px' }}>
              En cumplimiento con lo establecido en la Ley Orgánica de Protección de Datos Personales, Seguridad Munditrack se compromete a respetar los derechos, principios y obligaciones derivados de dicha ley.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: 2, fontSize: desktop ? '16px' : '12px' }}>
              El objetivo principal de esta política es garantizar la privacidad y seguridad de la información personal de los titulares de los datos personales, así como cumplir con los mejores estándares legales.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: 2, fontSize: desktop ? '16px' : '12px' }}>
              Agradecemos tu confianza en nosotros y queremos asegurarte que tus datos están en buenas manos. Para conocer a detalle nuestras políticas de protección de datos ingresa a
              <Link href="https://www.tracker.com.ec/politicageneral.html"> POLITICAS DE PRIVACIDAD</Link>
            </Typography>
            <div style={{ marginBottom: '4px' }}>
              <img src={aguila} alt="Política de Protección de Datos" style={{ maxWidth: '20%', height: 'auto', marginBottom: '16px' }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox checked={acceptPolicy} onChange={handleAcceptPolicyChange} />
              <Typography sx={{ fontSize: desktop ? '16px' : '13px' }}>He leído y acepto las políticas de privacidad</Typography>
            </div>
            <Button variant="contained" onClick={handleGuardarClick} disabled={!acceptPolicy}>Guardar y continuar</Button>
          </Paper>
        </div>
      </Modal>
      <Modal open={open1}>
        <div style={modalStyle}>
          <Paper sx={{ bgcolor: 'background.paper', p: 2, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              REGISTRO DE PALABRA CLAVE
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'justify', marginBottom: 2 }}>
              Por motivos de seguridad, solicitamos la introducción de una
              <strong>palabra clave</strong>
              que nuestro
              <strong>departamento de monitoreo</strong>
              requerirá para la
              <strong>verificación de identidad.</strong>
              Con el objetivo de facilitar la memorización de esta palabra, se ha limitado la selección a
              <strong>tres opciones. </strong>
              Es crucial que compartas esta palabra
              <strong>
                únicamente con personas de confianza
              </strong>
              ,ya que se utilizará exclusivamente con fines de autenticación y seguridad.
            </Typography>
            <Button variant="contained" onClick={abrirRegistro}>REGISTRO DE PALABRA CLAVE</Button>
          </Paper>
        </div>
      </Modal>
      <Modal open={open2}>
        <div style={modalStyle}>
          <Paper sx={{ bgcolor: 'background.paper', p: 2, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              PALABRA CLAVE
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormControl sx={{ marginRight: '10px', width: '50%' }}>
                <InputLabel htmlFor="select-type">Tipo</InputLabel>
                <Select
                  value={tipo}
                  onChange={(event) => setTipo(event.target.value)}
                  label="Opciones"
                  inputProps={{
                    name: 'Opciones',
                    id: 'select-type',
                  }}
                >
                  <MenuItem value="nombre">Nombre</MenuItem>
                  <MenuItem value="color">Color</MenuItem>
                  <MenuItem value="numero">Número</MenuItem>
                </Select>
              </FormControl>
              <TextField
                value={usuario.phone || ''}
                onChange={(event) => setUsuario({ ...usuario, phone: event.target.value })}
                label="Palabra Clave"
                style={{ width: '50%' }}
              />
            </Box>
            <Box
              sx={{
                justifyContent: 'center',
                marginTop: 1,
              }}
            >
              <Button variant="contained" onClick={actualizarUsuario}>
                GUARDAR
              </Button>
            </Box>
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

export default MainPage;
