import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion, AccordionDetails, AccordionSummary, Drawer, ListItem, IconButton, List, ListItemButton, ListItemText, Toolbar, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatNotificationTitle, formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { eventsActions } from '../store';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.dimensions.eventsDrawerWidth,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const EventsDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const devices = useSelector((state) => state.devices.items);

  const events = useSelector((state) => state.events.items);

  const formatType = (event) => formatNotificationTitle(t, {
    type: event.type,
    attributes: {
      alarms: event.attributes.alarm,
    },
  });

  const rows = events.map((event) => ({
    id: event.id,
    deviceId: event.deviceId,
    deviceName: devices[event.deviceId]?.name,
    eventType: formatType(event),
    eventTime: formatTime(event.eventTime),
  }));

  const groupByDeviceName = (data) => {
    const groups = {};
    data.forEach((item) => {
      if (!groups[item.deviceName]) {
        groups[item.deviceName] = {
          id: item.deviceName,
          deviceName: item.deviceName,
          children: [],
        };
      }
      groups[item.deviceName].children.push(item);
    });
    return Object.values(groups);
  };

  const groupedRows = groupByDeviceName(rows);

  const renderGroupedRows = (groupedRows) => (
    groupedRows.map((group) => (
      <Accordion key={group.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{group.deviceName}</Typography>
          <IconButton size="small" onClick={() => dispatch(eventsActions.deleteAllFromDevice(group.children.map((child) => child.id)))}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {group.children.map((row) => (
              <ListItem key={row.id}>
                <ListItemButton
                  key={row.id}
                  onClick={() => navigate(`/event/${row.id}`)}
                  disabled={!row.id}
                >
                  <ListItemText primary={`${row.eventType} •  ${devices[row.deviceId]?.placa} •  ${devices[row.deviceId]?.contact} •  ${devices[row.deviceId]?.model}`} secondary={`${row.eventTime}`} />
                </ListItemButton>
                <IconButton size="small" onClick={() => dispatch(eventsActions.delete(row))}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    ))
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Toolbar className={classes.toolbar} disableGutters>
        <Typography variant="h6" className={classes.title}>
          {t('reportEvents')}
        </Typography>
        <IconButton size="small" color="inherit" onClick={() => dispatch(eventsActions.deleteAll())}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Toolbar>
      <div>
        {renderGroupedRows(groupedRows)}
      </div>
    </Drawer>
  );
};

export default EventsDrawer;
