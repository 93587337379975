import { useEffect } from 'react';
// import maplibregl from 'maplibre-gl';
import { map } from './core/MapView';

const MapCamera = ({
  latitude, longitude, positions,
}) => {
  useEffect(() => {
    if (positions) {
      const coordinates = positions.map((item) => [item.longitude, item.latitude]);
      if (coordinates.length) {
        const [individual] = coordinates;
        map.jumpTo({
          center: individual,
          zoom: Math.max(map.getZoom(), 10),
        });
      }
    } else {
      map.jumpTo({
        center: [longitude, latitude],
        zoom: Math.max(map.getZoom(), 10),
      });
    }
  }, [latitude, longitude, positions]);

  return null;
};

export default MapCamera;
