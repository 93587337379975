import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCatch } from '../../reactHelper';
// import { snackBarDurationLongMs } from '../util/duration';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(1)})`,
    },
  },
  button: {
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.negative,
  },
}));

const UnLockDevice = ({
  open, onResult, device, comando, estado, chip, itemId,
}) => {
  const classes = useStyles();
  const [waitSnackbarOpen, setWaitSnackbarOpen] = useState(false);
  let datos1 = JSON.stringify({ deviceId: device, type: 'custom', attributes: { data: comando } });
  let datos2 = ' ';
  let url = 'https://tracker.com.ec/api/commands/send';
  if (estado === 'offline') {
    datos1 = JSON.stringify({ numero: chip, usuario: itemId, mensaje: comando });
    datos2 = JSON.stringify({ numero: chip, usuario: itemId, mensaje: '  getrecord' });
    url = 'https://tracker-clientes.onrender.com/soapReq';
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: datos1,
  };
  const requestOptions1 = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    body: datos2,
  };
  const handleUnLock = useCatch(async () => {
    setWaitSnackbarOpen(true);
    if (estado === 'offline') {
      await fetch(url, requestOptions1);
    }
    const response = await fetch(url, requestOptions);
    setWaitSnackbarOpen(false);
    if (response.ok) {
      onResult(true);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <>
      <Snackbar
        className={classes.root}
        open={open}
        autoHideDuration={2000}
        onClose={() => onResult(false)}
        message="¿Está seguro de desbloquear su vehículo?"
        action={(
          <Button size="small" className={classes.button} onClick={handleUnLock}>
            DESBLOQUEAR VEHÍCULO
          </Button>
        )}
      />
      <Snackbar
        className={classes.root}
        open={waitSnackbarOpen}
        message="ENVIANDO COMANDO - ESPERE UN MOMENTO"
      />
    </>
  );
};

export default UnLockDevice;
