// PAGINA QUE ENLISTA LOS USUARIOS

import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Button,
} from '@mui/material';
import FormatLineSpacing from '@mui/icons-material/FormatLineSpacing';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { usePreference } from '../common/util/preferences';
import { formatTime } from '../common/util/formatter';
import { useAdministrator } from '../common/util/permissions';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const DevicesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const hours12 = usePreference('twelveHourFormat');
  const administrador = useAdministrator();
  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  function sortByDate() {
    const sorted = [...items].sort((a, b) => {
      // Convierte las fechas a objetos Date para compararlas
      const dateA = new Date(a.fechafincontrato);
      const dateB = new Date(b.fechafincontrato);
      // Compara las fechas
      return dateA - dateB;
    });
    setItems(sorted);
  }

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('deviceIdentifier')}</TableCell>
            {administrador && (<TableCell>N. Chip</TableCell>)}
            <TableCell>Placa</TableCell>
            <TableCell>Chasis</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>
              {t('userExpirationTime')}
              {!administrador && (<Button type="button" onClick={() => sortByDate()}><FormatLineSpacing /></Button>)}
            </TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.uniqueId}</TableCell>
              {administrador && (<TableCell>{item.phone}</TableCell>)}
              <TableCell>{item.placa}</TableCell>
              <TableCell>{item.chasis}</TableCell>
              <TableCell>{item.motor}</TableCell>
              <TableCell>
                {formatTime(item.fechafincontrato, 'date', hours12)}
              </TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/device" endpoint="devices" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
      {administrador && <CollectionFab editPath="/settings/device" />}
    </PageLayout>
  );
};

export default DevicesPage;
