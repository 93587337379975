import React, { useState, useEffect } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import pdf from '../resources/politicas.pdf';
import SettingsMenu from './components/SettingsMenu';
import PageLayout from '../common/components/PageLayout';

const PoliticasPage = () => {
  const [viewPdf, setViewPdf] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      const pdfData = await fetch(pdf).then((res) => res.arrayBuffer());
      setViewPdf(new Uint8Array(pdfData));
    };

    fetchPdf();
  }, []);

  const newPlugin = defaultLayoutPlugin();

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'Politicas de Privacidad']}>
      <div className="pdf-container">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
          {viewPdf ? (
            <Viewer fileUrl={viewPdf} plugins={[newPlugin]} />
          ) : (
            <>NO PDF</>
          )}
        </Worker>
      </div>
    </PageLayout>
  );
};

export default PoliticasPage;
